import type { ThemeData } from './theme-types';
export * from './theme-types';

export let theme: ThemeData;

export async function setTheme(themeName: string) {
    switch (themeName) {
        case 'SOFTRIG':
            return import('./configs/softrig').then((t) => (theme = t.default));
        case 'SOFTRIG_TRAINING':
            return import('./configs/softrig-training').then((t) => (theme = t.default));
        case 'SB1':
            return import('./configs/sb1').then((t) => (theme = t.default));
        case 'DNB':
            return import('./configs/dnb').then((t) => (theme = t.default));
        case 'ELPROFFEN':
            return import('./configs/elproffen').then((t) => (theme = t.default));
        case 'UNIMICRO':
            return import('./configs/unimicro').then((t) => (theme = t.default));
        case 'AZETS':
            return import('./configs/azets').then((t) => (theme = t.default));
        case 'EIKA':
            return import('./configs/eika').then((t) => (theme = t.default));
        default:
            // REVISIT: default should be unimicro once the UE theme is gone for good
            return import('./configs/ue').then((t) => (theme = t.default));
    }
}
